import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import Scrollbar from 'react-scrollbars-custom';

import { clearOperations, getInvoicePDF, getOperations } from 'modules/profileLegasy/actions';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import MonthNavigation from 'modules/shared/components/MonthNavigation/MonthNavigation';
import NoOperationsMade from 'modules/shared/components/NoOperationsMade/NoOperationsMade';

import downloadFile from 'helpers/downloadFile';
import formatNumber from 'helpers/formatNumber';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { ReactComponent as IconBill } from 'static/assets/icon-bill.svg';
import { ReactComponent as IconClock } from 'static/assets/icon-clock.svg';
import { ReactComponent as IconCoins } from 'static/assets/icon-coins.svg';
import { ReactComponent as IconCard } from 'static/assets/icon-payment-process.svg';
import { ReactComponent as IconBusinessCard } from 'static/assets/icon-replenishment-via-business-card.svg';
import { ReactComponent as IconCashbox } from 'static/assets/icon-replenishment-via-cashbox.svg';
import { ReactComponent as IconMoneybox } from 'static/assets/icon-replenishment-via-moneybox.svg';
import { ReactComponent as IconSzCard } from 'static/assets/icon-sz-transfer.svg';
import { ReactComponent as IconWebMoney } from 'static/assets/icon-web-money-logo.svg';
import { ReactComponent as IconYandexWallet } from 'static/assets/icon-yandex-wallet.svg';

import './Operations.scss';
import moment from 'moment/moment';

const OPERATION_STATUS_SUCCESS = 'SUCCESS';
const OPERATION_STATUS_ERROR = 'ERROR';
const OPERATION_STATUS_PROCESS = 'PROCESS';
const OPERATION_STATUS_REJECTED = 'REJECTED';

const OPERATION_STATUS_MAP = {
  [OPERATION_STATUS_SUCCESS]: 'Успешно',
  [OPERATION_STATUS_ERROR]: 'Ошибка',
  [OPERATION_STATUS_PROCESS]: 'В обработке',
  [OPERATION_STATUS_REJECTED]: 'Отменено',
};

const CARD_TRANSFER = 'to-bankcard';
const WEBMONEY_TRANSFER = 'to-webmoney';
const YANDEX_TRANSFER = 'to-yandex';
const SZ_TRANSFER = 'to-sz-wallet';
const REPLENISHMENT_VIA_CASHBOX = 'receipt-by-cashbox';
const REPLENISHMENT_VIA_MONEYBOX = 'receipt-by-moneybox';
const REPLENISHMENT_VIA_CHECK = 'receipt-by-invoice';
const REPLENISHMENT_VIA_BUSINESS_CARD = 'receipt-by-business-card-link';
const REPLENISHMENT_VIA_MONEY_REQUEST = 'receipt-by-money-request';

const Operations = ({
  className,
  dispatch,
  operations,
  startZoneOffset,
  startCurrentTime,
  lastOperationsDate,
}) => {
  const [now, setNow] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [zoneOffset, setZoneOffset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFailToLoad, setIsFailToLoad] = useState(false);
  const [currentOpenOperationId, setCurrentOpenOperationId] = useState(null);

  const windowSize = useWindowSize();
  const pageSize = 7;

  /**
   * Берем  startZoneOffset и startCurrentTime с сервера, если они не равны нулю
   * записываем их в zoneOffset и now для построения фильтров, если же они равны 0
   * берем инфу о zoneOffset и now с браузера
   * */
  useEffect(() => {
    if (startCurrentTime && startCurrentTime !== 0) {
      setNow(new Date(startCurrentTime));
    } else {
      setNow(new Date());
    }

    if (startZoneOffset && startZoneOffset !== 0) {
      setZoneOffset(startZoneOffset);
    } else {
      now ? setZoneOffset(now.getTimezoneOffset()) : setZoneOffset(new Date().getTimezoneOffset());
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [startZoneOffset, startCurrentTime]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * Берем инфу по тому, когда были совершены последние операции
   * и сравниваем месяц и год с текущими
   * если не совпадают с теущими, значит строим фильтр от тех,
   * что пришли с севера (на запрос о дате последних операций)
   * */
  useEffect(() => {
    if (now && lastOperationsDate) {
      setMonth(
        +lastOperationsDate.slice(5, 7) !== now.getMonth() + 1
          ? +lastOperationsDate.slice(5, 7)
          : now.getMonth() + 1,
      );
      setYear(
        +lastOperationsDate.slice(0, 4) !== now.getFullYear()
          ? +lastOperationsDate.slice(0, 4)
          : now.getFullYear(),
      );
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [lastOperationsDate, now]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Делаем запрос на операции юзера */
  const getOperationsForPage = useCallback(
    (page) => {
      setIsLoading(true);
      setIsFailToLoad(false);

      dispatch(getOperations(month, year, page, pageSize, zoneOffset))
        .then((res) => {
          if (res && res.data && res.data.numberOfPages != null) {
            const isItLastPage = res.data.numberOfPages <= page;
            setHasMore(!isItLastPage);
          }
        })
        .catch((result) => {
          setHasMore(false);
          setIsFailToLoad(false);

          if (result && result.result && result.result.code === 400) {
            setIsLoading(false);
            setIsFailToLoad(true);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [month, year, dispatch, zoneOffset],
  );

  /** Загружаем первую порцию операций для текущего месяца и года */
  useEffect(() => {
    if (month && year) {
      getOperationsForPage(1);
    }
  }, [month, year, zoneOffset, getOperationsForPage]);

  /** Клик на предыдущий месяц */
  const handlePrevMonthClick = () => {
    /**
     * Это нужно для того, чтобы когда мы переключаемся на другой месяц,
     * а запрос на операции нового месяца сфелися, чтобы нам не показывались
     * операции из предыдущего месяца
     * */
    dispatch(clearOperations());

    if (month <= 1) {
      setMonth(12);
      setYear(Math.max(2019, year - 1));
    } else {
      setMonth(month - 1);
    }
  };

  /** Клик на следующий месяц */
  const handleNextMonthClick = () => {
    /**
     * Это нужно для того, чтобы когда мы переключаемся на другой месяц,
     * а запрос на операции нового месяца сфелися, чтобы нам не показывались
     * операции из предыдущего месяца
     * */
    dispatch(clearOperations());

    if (month >= 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  /** Текущая дата, выбранная в навигации */
  const currentDate = new Date(Date.parse(`${year}-${month}-01`));
  const firstMonthDayOfNow = new Date(
    (now || new Date()).getFullYear(),
    (now || new Date()).getMonth(),
    1,
    0,
    0,
    0,
    0,
  );

  const visibleAttr = [
    'comment',
    'pay-it.payer.phone',
    'pay-it.payer.comment',
    'pay-it.payer.email',
    'pay-it.payer.fio',
  ];

  /** Запрос на генерацию PDF для скачивания чека */
  const handleDownloadInvoice = (e, invoiceId) => {
    e.preventDefault();

    dispatch(getInvoicePDF(invoiceId))
      .then((response) => {
        if (response) {
          const blob = new Blob([response], { type: 'application/pdf' });

          downloadFile(blob, `samozanyatye-${invoiceId}.pdf`);
        }
      })
      .catch(() => {});
  };

  return (
    <div
      className={cx('operations', {
        [className]: className,
      })}
    >
      {windowSize.innerWidth > 980 && (
        <>
          {/* ХЭДЕР ТАБЛИЦЫ */}
          <div className="operations__header">
            <div className="operations__item-title-wrap">
              <div className="operations__item-icon-wrap">
                <IconClock className="operations__item-icon operations__item-icon_clock" />
              </div>
              <p className="operations__item-title">История операций</p>
            </div>

            <MonthNavigation
              className="operations__item-date"
              month={month}
              year={year}
              isLoading={isLoading}
              isPrevButtonDisabled={year === 2019 && month === 1}
              isNextButtonDisabled={currentDate >= firstMonthDayOfNow}
              handlePrevMonthClick={handlePrevMonthClick}
              handleNextMonthClick={handleNextMonthClick}
            />

            <p className="operations__item-sum">Сумма</p>
          </div>
          {/* /ХЭДЕР ТАБЛИЦЫ */}

          {/* КОНТЕНТ ТАБЛИЦЫ */}
          <div className="operations__content-wrapper">
            <Scrollbar
              className="operations__content-scrollbar"
              trackYProps={{
                /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
                renderer: ({ elementRef, style, ...restProps }) => {
                  return <span {...restProps} ref={elementRef} className="operations__track" />;
                },
              }}
              thumbYProps={{
                renderer: ({ elementRef, style, ...restProps }) => {
                  return <span {...restProps} ref={elementRef} className="operations__thumb" />;
                },
                /* eslint-enable react/prop-types, react/jsx-props-no-spreading */
              }}
            >
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={getOperationsForPage}
                hasMore={hasMore}
                useWindow={false}
                key={`${month}_${year}`}
                getScrollParent={() => document.querySelector('.ScrollbarsCustom-Scroller')}
              >
                <div className="operations__list">
                  {operations &&
                    operations.payments &&
                    operations.payments.map((item) => (
                      <button
                        type="button"
                        className={cx('operations__item', {
                          operations__item_open: currentOpenOperationId === item.id,
                        })}
                        key={item.id}
                        onClick={(e) => {
                          if (e.target.className === 'operations__item-download-bill-link') return;
                          setCurrentOpenOperationId((prevState) =>
                            prevState === item.id ? null : item.id,
                          );
                        }}
                      >
                        {/* ВИДИМАЯ ЧАСТЬ */}
                        <div className="operations__item-top">
                          <div className="operations__item-title-wrap">
                            <div className="operations__item-icon-wrap">
                              {item.logo === CARD_TRANSFER && (
                                <IconCard className="operations__item-icon operations__item-icon_card" />
                              )}

                              {item.logo === WEBMONEY_TRANSFER && (
                                <IconWebMoney className="operations__item-icon operations__item-icon_web-money" />
                              )}

                              {item.logo === YANDEX_TRANSFER && (
                                <IconYandexWallet className="operations__item-icon operations__item-icon_yandex" />
                              )}

                              {item.logo === SZ_TRANSFER && (
                                <IconSzCard className="operations__item-icon operations__item-icon_sz" />
                              )}

                              {item.logo === REPLENISHMENT_VIA_CHECK && (
                                <IconBill className="operations__item-icon operations__item-icon_bill" />
                              )}

                              {item.logo === REPLENISHMENT_VIA_BUSINESS_CARD && (
                                <IconBusinessCard className="operations__item-icon operations__item-icon_business-card" />
                              )}

                              {item.logo === REPLENISHMENT_VIA_MONEY_REQUEST && (
                                <IconCoins className="operations__item-icon operations__item-icon_coins" />
                              )}

                              {item.logo === REPLENISHMENT_VIA_MONEYBOX && (
                                <IconMoneybox className="operations__item-icon operations__item-icon_moneybox" />
                              )}

                              {item.logo === REPLENISHMENT_VIA_CASHBOX && (
                                <IconCashbox className="operations__item-icon operations__item-icon_chashbox" />
                              )}

                              {item.logo !== CARD_TRANSFER &&
                                item.logo !== WEBMONEY_TRANSFER &&
                                item.logo !== YANDEX_TRANSFER &&
                                item.logo !== SZ_TRANSFER &&
                                item.logo !== REPLENISHMENT_VIA_CHECK &&
                                item.logo !== REPLENISHMENT_VIA_BUSINESS_CARD &&
                                item.logo !== REPLENISHMENT_VIA_CASHBOX &&
                                item.logo !== REPLENISHMENT_VIA_MONEYBOX &&
                                item.logo !== REPLENISHMENT_VIA_MONEY_REQUEST && (
                                  <IconCard className="operations__item-icon operations__item-icon_card" />
                                )}
                            </div>

                            <p className="operations__item-title">{item.name}</p>
                          </div>
                          <p className="operations__item-date">{prepareDate(item.dateTimeAdd)}</p>

                          <p
                            className={cx('operations__item-sum', {
                              'operations__item-sum_minus': item.writeOff,
                              'operations__item-sum_unsuccess':
                                item.state !== OPERATION_STATUS_SUCCESS,
                            })}
                          >
                            {item.state === OPERATION_STATUS_SUCCESS && (
                              <>{item.writeOff ? '-' : '+'}</>
                            )}{' '}
                            {formatNumber(item.sum / 100)} ₽
                          </p>
                        </div>
                        {/* /ВИДИМАЯ ЧАСТЬ */}

                        {/* ВЫПАДАШКА АЙТЕМА */}
                        <div className="operations__item-bottom">
                          <div className="operations__item-additional-info">
                            {item.state &&
                              [
                                OPERATION_STATUS_SUCCESS,
                                OPERATION_STATUS_PROCESS,
                                OPERATION_STATUS_ERROR,
                                OPERATION_STATUS_REJECTED,
                              ].includes(item.state) && (
                                <div className="operations__item-message-wrap">
                                  <p className="operations__item-message-title">Статус перевода:</p>
                                  <p
                                    className={cx('operations__item-message-text', {
                                      'operations__item-message-text_green':
                                        item.state === OPERATION_STATUS_SUCCESS,
                                      'operations__item-message-text_red': [
                                        OPERATION_STATUS_ERROR,
                                        OPERATION_STATUS_REJECTED,
                                      ].includes(item.state),
                                      'operations__item-message-text_orange':
                                        item.state === OPERATION_STATUS_PROCESS,
                                    })}
                                  >
                                    {OPERATION_STATUS_MAP[item.state]}
                                  </p>
                                </div>
                              )}

                            {item.cardPan && (
                              <div className="operations__item-message-wrap">
                                <p className="operations__item-message-title">Номер карты:</p>
                                <p className="operations__item-message-text">{item.cardPan}</p>
                              </div>
                            )}

                            {item.commentary && (
                              <div className="operations__item-message-wrap">
                                <p className="operations__item-message-title">
                                  Сообщение получателю:
                                </p>
                                <p className="operations__item-message-text">{item.commentary}</p>
                              </div>
                            )}

                            <div className="operations__item-message-wrap">
                              <p className="operations__item-message-title">Информация:</p>
                              <p className="operations__item-message-text">Операция № {item.id}</p>
                            </div>

                            {item?.attributes?.map((attr, i) => {
                              if (visibleAttr.includes(attr.name)) {
                                return (
                                  <div key={i}>
                                    <div className="operations__item-message-wrap">
                                      <p className="operations__item-message-title">
                                        {attr.title}:
                                      </p>
                                      <p className="operations__item-message-text">
                                        {attr.view ? attr.view : attr.value}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                              return '';
                            })}
                          </div>

                          {item.state && item.state === OPERATION_STATUS_SUCCESS && (
                            <a
                              href="/"
                              className="operations__item-download-bill-link"
                              onClick={(e) => handleDownloadInvoice(e, item.id)}
                            >
                              скачать чек
                            </a>
                          )}
                        </div>
                        {/* /ВЫПАДАШКА АЙТЕМА */}
                      </button>
                    ))}
                </div>
              </InfiniteScroll>

              {isLoading && (
                <div
                  className={cx('operations__loading', {
                    // 'operations__loading_full-block': currentPage === 1,
                  })}
                >
                  <CircleLoader className="operations__loading-loader" />
                </div>
              )}

              {/* СООБЩЕНИЕ КОГДА НЕТ ОПЕРАЦИЙ */}
              {operations && operations.payments === null && !isLoading && !isFailToLoad && (
                <NoOperationsMade
                  text="Здесь пока пусто. Как только вы начнете совершать операции, они будут отражаться в этом разделе"
                  className="operations__no-operations-message"
                />
              )}
              {/* /СООБЩЕНИЕ КОГДА НЕТ ОПЕРАЦИЙ */}

              {/* СООБЩЕНИЕ КОГДА НЕ УДАЛОСЬ ЗАГРУЗИТЬ ОПЕРАЦИИ */}
              {isFailToLoad && !isLoading && !hasMore && (
                <NoOperationsMade className="operations__no-operations-message">
                  <div className="no-operations-made__text">
                    Произошла какая-то ошибка. Не удалось загрузить данные. Попробуйте позже, а если
                    ошибка не пройдет
                    <button className="no-operations-made__text-link js-help" type="button">
                      напишите нам
                    </button>
                    .
                  </div>
                </NoOperationsMade>
              )}
              {/* /СООБЩЕНИЕ КОГДА НЕ УДАЛОСЬ ЗАГРУЗИТЬ ОПЕРАЦИИ */}
            </Scrollbar>
          </div>
          {/* /КОНТЕНТ ТАБЛИЦЫ */}
        </>
      )}

      {windowSize.innerWidth <= 980 && (
        <>
          {/* НАВИГАЦИЯ ПО МЕСЯЦАМ */}
          <MonthNavigation
            className="operations__month-navigation"
            month={month}
            year={year}
            isLoading={isLoading}
            isPrevButtonDisabled={year === 2019 && month === 1}
            isNextButtonDisabled={currentDate >= firstMonthDayOfNow}
            handlePrevMonthClick={handlePrevMonthClick}
            handleNextMonthClick={handleNextMonthClick}
          />
          {/* /НАВИГАЦИЯ ПО МЕСЯЦАМ */}

          {/* КОНТЕНТ ТАБЛИЦЫ */}
          <div className="operations__content-wrapper">
            <Scrollbar
              className="operations__content-scrollbar"
              trackYProps={{
                /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
                renderer: ({ elementRef, style, ...restProps }) => {
                  return <span {...restProps} ref={elementRef} className="operations__track" />;
                },
              }}
              thumbYProps={{
                renderer: ({ elementRef, style, ...restProps }) => {
                  return <span {...restProps} ref={elementRef} className="operations__thumb" />;
                },
                /* eslint-enable react/prop-types, react/jsx-props-no-spreading */
              }}
            >
              <InfiniteScroll
                pageStart={1} // It is second actual
                initialLoad={false}
                loadMore={getOperationsForPage}
                hasMore={hasMore}
                useWindow={false}
                key={`${month}_${year}`}
                getScrollParent={() => document.querySelector('.ScrollbarsCustom-Scroller')}
              >
                <div className="operations__list">
                  {operations &&
                    operations.payments &&
                    operations.payments.map((item) => (
                      <button
                        type="button"
                        className={cx('operations__item', {
                          operations__item_open: currentOpenOperationId === item.id,
                        })}
                        key={item.id}
                        onClick={() => setCurrentOpenOperationId(item.id)}
                      >
                        {/* ВИДИМАЯ ЧАСТЬ */}
                        <div className="operations__item-top">
                          <div className="operations__item-title-wrap">
                            <div className="operations__item-icon-wrap">
                              {item.logo === CARD_TRANSFER && (
                                <IconCard className="operations__item-icon operations__item-icon_card" />
                              )}

                              {item.logo === WEBMONEY_TRANSFER && (
                                <IconWebMoney className="operations__item-icon operations__item-icon_web-money" />
                              )}

                              {item.logo === YANDEX_TRANSFER && (
                                <IconYandexWallet className="operations__item-icon operations__item-icon_yandex" />
                              )}

                              {item.logo === SZ_TRANSFER && (
                                <IconSzCard className="operations__item-icon operations__item-icon_sz" />
                              )}

                              {item.logo === REPLENISHMENT_VIA_CHECK && (
                                <IconBill className="operations__item-icon operations__item-icon_bill" />
                              )}

                              {item.logo === REPLENISHMENT_VIA_BUSINESS_CARD && (
                                <IconBusinessCard className="operations__item-icon operations__item-icon_business-card" />
                              )}

                              {item.logo === REPLENISHMENT_VIA_MONEY_REQUEST && (
                                <IconCoins className="operations__item-icon operations__item-icon_coins" />
                              )}

                              {item.logo !== CARD_TRANSFER &&
                                item.logo !== WEBMONEY_TRANSFER &&
                                item.logo !== YANDEX_TRANSFER &&
                                item.logo !== SZ_TRANSFER &&
                                item.logo !== REPLENISHMENT_VIA_CHECK &&
                                item.logo !== REPLENISHMENT_VIA_BUSINESS_CARD &&
                                item.logo !== REPLENISHMENT_VIA_MONEY_REQUEST && (
                                  <IconCard className="operations__item-icon operations__item-icon_card" />
                                )}
                            </div>

                            <p className="operations__item-title">{item.name}</p>
                          </div>

                          <div className="operations__item-right-part">
                            <p
                              className={cx('operations__item-sum', {
                                'operations__item-sum_minus': item.writeOff,
                                'operations__item-sum_unsuccess':
                                  item.state !== OPERATION_STATUS_SUCCESS,
                              })}
                            >
                              {item.state === OPERATION_STATUS_SUCCESS && (
                                <>{item.writeOff ? '-' : '+'}</>
                              )}{' '}
                              {formatNumber(item.sum / 100)} ₽
                            </p>

                            <p className="operations__item-date">{prepareDate(item.dateTimeAdd)}</p>
                          </div>
                        </div>
                        {/* /ВИДИМАЯ ЧАСТЬ */}

                        {/* ВЫПАДАШКА АЙТЕМА */}
                        <div className="operations__item-bottom">
                          <div className="operations__item-additional-info">
                            {item.state &&
                              [
                                OPERATION_STATUS_SUCCESS,
                                OPERATION_STATUS_PROCESS,
                                OPERATION_STATUS_ERROR,
                                OPERATION_STATUS_REJECTED,
                              ].includes(item.state) && (
                                <div className="operations__item-message-wrap">
                                  <p className="operations__item-message-title">Статус перевода:</p>
                                  <p
                                    className={cx('operations__item-message-text', {
                                      'operations__item-message-text_green':
                                        item.state === OPERATION_STATUS_SUCCESS,
                                      'operations__item-message-text_red': [
                                        OPERATION_STATUS_ERROR,
                                        OPERATION_STATUS_REJECTED,
                                      ].includes(item.state),
                                      'operations__item-message-text_orange':
                                        item.state === OPERATION_STATUS_PROCESS,
                                    })}
                                  >
                                    {OPERATION_STATUS_MAP[item.state]}
                                  </p>
                                </div>
                              )}

                            {item.cardPan && (
                              <div className="operations__item-message-wrap">
                                <p className="operations__item-message-title">Номер карты:</p>
                                <p className="operations__item-message-text">{item.cardPan}</p>
                              </div>
                            )}

                            {item.commentary && (
                              <div className="operations__item-message-wrap">
                                <p className="operations__item-message-title">
                                  Сообщение получателю:
                                </p>
                                <p className="operations__item-message-text">{item.commentary}</p>
                              </div>
                            )}

                            <div className="operations__item-message-wrap">
                              <p className="operations__item-message-title">Информация:</p>
                              <p className="operations__item-message-text">Операция № {item.id}</p>
                            </div>

                            {item?.attributes?.map((attr, i) => {
                              if (attr.name && visibleAttr.includes(attr.name)) {
                                return (
                                  <div key={i}>
                                    <div className="operations__item-message-wrap">
                                      <p className="operations__item-message-title">
                                        {attr.title}:
                                      </p>
                                      <p className="operations__item-message-text">
                                        {attr.view ? attr.view : attr.value}
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                              return '';
                            })}
                          </div>

                          {item.state && item.state === OPERATION_STATUS_SUCCESS && (
                            <a
                              href="/"
                              className="operations__item-download-bill-link"
                              onClick={(e) => handleDownloadInvoice(e, item.id)}
                            >
                              скачать чек
                            </a>
                          )}
                        </div>
                        {/* /ВЫПАДАШКА АЙТЕМА */}
                      </button>
                    ))}
                </div>

                {isLoading && (
                  <div className="operations__loading">
                    <CircleLoader className="operations__loading-loader" />
                  </div>
                )}
              </InfiniteScroll>

              {false && operations && operations.payments != null && hasMore && (
                <div className="operations__next-page-btn-wrap">
                  <button className="operations__next-page-btn" type="button">
                    <IconArrow className="operations__next-page-btn-icon" />
                  </button>
                </div>
              )}

              {/* СООБЩЕНИЕ КОГДА НЕТ ОПЕРАЦИЙ */}
              {operations && operations.payments === null && !isLoading && !isFailToLoad && (
                <NoOperationsMade
                  text="Здесь пока пусто. Как только вы начнете совершать операции, они будут отражаться в этом разделе"
                  className="operations__no-operations-message"
                />
              )}
              {/* /СООБЩЕНИЕ КОГДА НЕТ ОПЕРАЦИЙ */}

              {/* СООБЩЕНИЕ КОГДА НЕ УДАЛОСЬ ЗАГРУЗИТЬ ОПЕРАЦИИ */}
              {isFailToLoad && !isLoading && (
                <NoOperationsMade className="deals__no-operations-message">
                  <div className="no-operations-made__text">
                    Произошла какая-то ошибка. Не удалось загрузить данные. Попробуйте позже, а если
                    ошибка не пройдет {'   '}
                    <button className="no-operations-made__text-link js-help" type="button">
                      напишите нам
                    </button>
                    .
                  </div>
                </NoOperationsMade>
              )}
              {/* /СООБЩЕНИЕ КОГДА НЕ УДАЛОСЬ ЗАГРУЗИТЬ ОПЕРАЦИИ */}
            </Scrollbar>
          </div>
          {/* /КОНТЕНТ ТАБЛИЦЫ */}
        </>
      )}
    </div>
  );
};

const prepareDate = (date: string) => {
  const preparedDate = moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);
  if (preparedDate.isValid()) {
    return preparedDate.format('DD.MM.YYYY (HH:mm)');
  } else {
    return date;
  }
};

Operations.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  operations: PropTypes.shape({
    numberOfPages: PropTypes.number,
    pageSize: PropTypes.number,
    payments: PropTypes.arrayOf(
      PropTypes.shape({
        commentary: PropTypes.string,
        logo: PropTypes.string,
        name: PropTypes.string,
        dateTimeAdd: PropTypes.string,
        sum: PropTypes.number,
        writeOff: PropTypes.bool,
        id: PropTypes.number,
        cardPan: PropTypes.string,
      }),
    ),
    total: PropTypes.number,
  }).isRequired,
  startZoneOffset: PropTypes.number.isRequired,
  startCurrentTime: PropTypes.number.isRequired,
  lastOperationsDate: PropTypes.string,
};

Operations.defaultProps = {
  className: null,
  lastOperationsDate: null,
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.auth.isMenuOpen,
  operations: state.profile.operations,
  startZoneOffset: state.auth.startZoneOffset || 0,
  startCurrentTime: state.auth.startCurrentTime,
  lastOperationsDate: state.profile.lastOperationsDate,
});

export default connect(mapStateToProps)(Operations);
