import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useToggleAndOutClick from 'use-toggle-and-outclick';

import ProfileErrorMessage from 'modules/profileLegasy/components/ProfileErrorMessage/ProfileErrorMessage';
import InputWithLabelAnimation from 'modules/shared/components/InputWithLabelAnimation/InputWithLabelAnimation';
import PageTitle from 'modules/shared/components/PageTitle/PageTitle';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import TextareaWithLabelAnimation from 'modules/shared/components/TextareaWithLabelAnimation/TextareaWithLabelAnimation';

import { BLUE, GREY_BORDER } from 'constants/index';

import { ReactComponent as IconBag } from 'static/assets/icon-bag.svg';
import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';
import { ReactComponent as IconUser } from 'static/assets/icon-profile-settings-person.svg';

import './AcquiringSettingsStep1.scss';

const LOADING = 'LOADING';
const FAIL = 'FAIL';

const AcquiringSettingsStep1 = ({
  domain,
  setDomain,
  aboutBusinessText,
  setAboutBusinessText,
  isUserSz,
  createDomainToken,
  domainTokenCreationStatus,
  failMessage,
  currentActiveMerchantSzStatusId,
  setCurrentActiveMerchantSzStatusId,
}) => {
  const [isDropOpen, dropEl, toggleDrop] = useToggleAndOutClick();

  const statusButtons = [
    {
      id: 0,
      title: 'Я физ. лицо',
      icon: (
        <IconUser className="acquiring-settings-step-1__status-item-icon acquiring-settings-step-1__status-item-icon_user" />
      ),
      isActive: currentActiveMerchantSzStatusId === 0,
    },
    {
      id: 1,
      title: 'Я самозанятый',
      icon: (
        <IconBag className="acquiring-settings-step-1__status-item-icon acquiring-settings-step-1__status-item-icon_bag" />
      ),
      isActive: currentActiveMerchantSzStatusId === 1,
    },
  ];

  return (
    <>
      <div className="acquiring-settings-step-1-step-1">
        <PageTitle className="acquiring-settings-step-1__title" title="Домен и описание магазина" />

        <div className="acquiring-settings-step-1__content">
          <div className="acquiring-settings-step-1__form">
            {/* ВЫБОР СТАТУСА МЕРЧАНТА */}
            <div className="acquiring-settings-step-1__status-block">
              <div className="acquiring-settings-step-1__status-title-wrap">
                <div className="acquiring-settings-step-1__status-title">Ваш статус</div>
                <div className="acquiring-settings-step-1__status-title-button-wrap">
                  <button
                    type="button"
                    onClick={toggleDrop}
                    className="acquiring-settings-step-1__status-title-button"
                  >
                    <IconInfo className="acquiring-settings-step-1__status-title-icon" />
                  </button>

                  {isDropOpen && (
                    <div className="acquiring-settings-step-1__status-title-dropdown" ref={dropEl}>
                      Для приема платежей связанных с коммерческой деятельностью, необходим статус
                      самозанятого
                    </div>
                  )}
                </div>
              </div>

              <div className="acquiring-settings-step-1__status-list-wrap">
                <div className="acquiring-settings-step-1__status-list">
                  {statusButtons.map((item) => (
                    <button
                      className={cx('acquiring-settings-step-1__status-item', {
                        'acquiring-settings-step-1__status-item_active':
                          currentActiveMerchantSzStatusId === item.id,
                      })}
                      key={item.id}
                      data-testid="acquiring-settings-step-1-status-item"
                      type="button"
                      disabled={!isUserSz}
                      onClick={() => setCurrentActiveMerchantSzStatusId(item.id)}
                    >
                      {item.icon}
                      {item.title}
                    </button>
                  ))}
                </div>

                {currentActiveMerchantSzStatusId === 0 && (
                  <p className="acquiring-settings-step-1__individual-warning">
                    Физ. лицу доступен прием платежей только для некоммерческих целей
                  </p>
                )}
              </div>
            </div>
            {/* /ВЫБОР СТАТУСА МЕРЧАНТА */}

            <InputWithLabelAnimation
              value={domain}
              label="Домен"
              onChange={(event) => setDomain(event.target.value.toLowerCase())}
              className="acquiring-settings-step-1__input"
              prevText="https://"
              prevTextWidth={74}
            />

            <TextareaWithLabelAnimation
              value={aboutBusinessText}
              label=""
              placeholder="Расскажите подробнее о вашем магазине: назначение платежей и целевую аудиторию"
              onChange={(event) => setAboutBusinessText(event.target.value)}
              className="acquiring-settings-step-1__textarea"
              maxLength={255}
            />

            <div className="acquiring-settings-step-1__buttons-list">
              <StandardButton
                title="Вернуться"
                isLink
                href="/lk/acquiring"
                bgType={GREY_BORDER}
                className="acquiring-settings-step-1__buttons-item"
              />
              <StandardButton
                title="Продолжить"
                isLink={false}
                bgType={BLUE}
                className="acquiring-settings-step-1__buttons-item"
                disabled={!(domain && aboutBusinessText && domain.indexOf('.') > -1)}
                onClick={createDomainToken}
                isLoading={domainTokenCreationStatus === LOADING}
              />
            </div>
          </div>

          <div className="acquiring-settings-step-1__message-wrap">
            <div className="acquiring-settings-step-1__documentation">
              <span
                className="acquiring-settings-step-1__documentation-item"
                onClick={() => window.open('https://docs.selfwork.ru/api/v/acquiring/', '_blank')}
              >
                Документация
              </span>
            </div>

            <ProfileErrorMessage animationStart className="acquiring-settings-step-1__message">
              <div className="profile-error-message__text">
                Если у вас возникли проблемы с добавлением магазина обратитесь в {'  '}
                <span className="js-help profile-error-message__link">службу поддержки</span>.
              </div>
            </ProfileErrorMessage>

            <ProfileErrorMessage
              animationStart={domainTokenCreationStatus === FAIL}
              className="acquiring-settings-step-1__message"
            >
              <div className="profile-error-message__text">
                {failMessage}. {'  '} Если проблема не решится, обратитесь в {'  '}
                <span className="js-help profile-error-message__link">службу поддержки</span>.
              </div>
            </ProfileErrorMessage>
          </div>
        </div>
      </div>
    </>
  );
};

AcquiringSettingsStep1.propTypes = {
  domain: PropTypes.string,
  setDomain: PropTypes.func.isRequired,
  aboutBusinessText: PropTypes.string,
  setAboutBusinessText: PropTypes.func.isRequired,
  isUserSz: PropTypes.bool.isRequired,
  createDomainToken: PropTypes.func.isRequired,
  domainTokenCreationStatus: PropTypes.string,
  failMessage: PropTypes.string,
  currentActiveMerchantSzStatusId: PropTypes.number.isRequired,
  setCurrentActiveMerchantSzStatusId: PropTypes.func.isRequired,
};

AcquiringSettingsStep1.defaultProps = {
  domain: '',
  aboutBusinessText: '',
  domainTokenCreationStatus: null,
  failMessage: null,
};

export default AcquiringSettingsStep1;
