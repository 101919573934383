import React from 'react';

const HintWhenToPayTax = () => (
  <div className="hint__text-wrap">
    <div className="hint__text">
      Оплата налога (НПД) производится ежемесячно не позднее 28 числа месяца, следующего за истекшим
      налоговым периодом (месяцем). Если эта дата является праздничным или выходным днем, тогда срок
      уплаты переносится на первый рабочий день.
    </div>
  </div>
);

export default HintWhenToPayTax;
