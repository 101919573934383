import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import {
  confirmDomainToken,
  createDomainToken,
  downloadDomainToken,
} from 'modules/acquiring/actions';
import AcquiringGettingStep from 'modules/acquiring/components/AcquiringGettingStep/AcquiringGettingStep';
import AcquiringSettingsStep1 from 'modules/acquiring/components/AcquiringSettingsStep1/AcquiringSettingsStep1';
import AcquiringSettingsStep2 from 'modules/acquiring/components/AcquiringSettingsStep2/AcquiringSettingsStep2';
import AcquiringSettingsStep3 from 'modules/acquiring/components/AcquiringSettingsStep3/AcquiringSettingsStep3';

import { CONSISTENT_ANIMATIONS_DELAY, PARTLY_YES, YES } from 'constants/index';
import downloadFile from 'helpers/downloadFile';
import { parseUri } from 'helpers/index';

import './AcquiringSettings.scss';
import { OnlyAuthorizedIdentifyPopup } from '../../../../tools/utils/only-authorized';
import { checkPaymentEnabled, PaymentDisabledPopup } from '../../../../tools/utils/payments';

const SUCCESS = 'SUCCESS';
const LOADING = 'LOADING';
const FAIL = 'FAIL';

const AcquiringSettings = ({ userTaxStatus, szStatusFromDb, user, dispatch }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [domain, setDomain] = useState();
  const [approvedDomain, setApprovedDomain] = useState('');
  const [aboutBusinessText, setAboutBusinessText] = useState('');
  const [confirmToken, setConfirmToken] = useState('');
  const isUserSz = Boolean(
    userTaxStatus === YES || (szStatusFromDb && szStatusFromDb === PARTLY_YES),
  );
  const [storeId, setStoreId] = useState('');

  const [domainTokenCreationStatus, setDomainTokenCreationStatus] = useState(null);
  const [domainConfirmationStatus, setDomainConfirmationStatus] = useState(null);
  const [downloadFileStatus, setDownloadFileStatus] = useState(null);
  const [failMessage, setFailMessage] = useState(null);
  const [currentActiveMerchantSzStatusId, setCurrentActiveMerchantSzStatusId] = useState(
    isUserSz ? 1 : 0,
  );

  const steps = [
    {
      id: 1,
      title: 'Домен и описание магазина',
      isDisabled: activeStep === 3,
      onClick: () => {}, //setActiveStep(1),
      isGotten:
        (domainTokenCreationStatus === SUCCESS || domainConfirmationStatus === SUCCESS) &&
        domain === approvedDomain,
    },
    {
      id: 2,
      title: 'Подтверждение магазина',
      isDisabled: activeStep === 1 && approvedDomain !== domain,
      onClick: () => {}, //setActiveStep(2),
      isGotten: domainConfirmationStatus === SUCCESS,
    },
    {
      id: 3,
      title: 'Настройка магазина',
      onClick: () => {}, //setActiveStep(3),
      isDisabled: true,
    },
  ];

  /** Отправляем домен на проверку для получения токена */
  const createDomainTokenHandler = () => {
    if (domain === approvedDomain) {
      setActiveStep(2);
    } else {
      setDomainTokenCreationStatus(LOADING);

      dispatch(
        createDomainToken(
          aboutBusinessText,
          `https://${parseUri(domain).host}`,
          Boolean(currentActiveMerchantSzStatusId),
        ),
      )
        .then((res) => {
          setConfirmToken(res && res.data && res.data.confirmToken);
          setActiveStep(2);
          setApprovedDomain(parseUri(domain).host);
          setDomainTokenCreationStatus(SUCCESS);
        })
        .catch((err) => {
          setDomainTokenCreationStatus(FAIL);
          setFailMessage(err && err.result && err.result.message);
        });
    }
  };

  /** Подтверждаем домен */
  const confirmDomainTokenHandler = () => {
    setDomainConfirmationStatus(LOADING);
    setDownloadFileStatus(null);
    setFailMessage(null);

    dispatch(confirmDomainToken(`https://${parseUri(domain).host}`))
      .then((res) => {
        if (res && res.data && res.data.uuid) {
          setStoreId(res.data.uuid);
          setActiveStep(3);
        }
        if (res && res.data && !res.data.confirmed) {
          setDomainConfirmationStatus(FAIL);
        }
      })
      .catch((err) => {
        setDomainConfirmationStatus(FAIL);
        setFailMessage(err && err.result && err.result.message);
      });
  };

  /** Скачиваем файл для подключения плагина */
  const downloadFileForConfirmation = () => {
    setDownloadFileStatus(LOADING);

    /* eslint-disable no-console */
    dispatch(downloadDomainToken(`https://${parseUri(domain).host}`))
      .then((response) => {
        const blob = new Blob([response], { type: 'application/txt' });

        downloadFile(blob, `selfwork-site-association.txt`);
        setDownloadFileStatus(null);
      })
      .catch((err) => {
        console.log(err);
        setDownloadFileStatus(FAIL);
      });
    /* eslint-enable no-console */
  };

  return (
    <>
      <PaymentDisabledPopup redirect={'/lk'} />
      {checkPaymentEnabled() && <OnlyAuthorizedIdentifyPopup user={user} redirect={'/lk'} />}
      <div className="acquiring-settings">
        <div className="acquiring-settings__steps">
          {steps.map((item) => (
            <AcquiringGettingStep
              title={item.title}
              key={item.id}
              id={item.id}
              isDisabled={item.isDisabled}
              className="acquiring-settings__steps-item"
              activeStep={activeStep}
              isGotten={item.isGotten}
              onClick={item.onClick}
            />
          ))}
        </div>

        <div className="acquiring-settings__main">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={activeStep === 1}
            timeout={{ enter: 50, exit: 50, enterDelay: 1000 }}
            unmountOnExit
          >
            <AcquiringSettingsStep1
              domain={domain}
              setDomain={setDomain}
              aboutBusinessText={aboutBusinessText}
              setAboutBusinessText={setAboutBusinessText}
              isUserSz={isUserSz}
              createDomainToken={createDomainTokenHandler}
              domainTokenCreationStatus={domainTokenCreationStatus}
              failMessage={failMessage}
              currentActiveMerchantSzStatusId={currentActiveMerchantSzStatusId}
              setCurrentActiveMerchantSzStatusId={setCurrentActiveMerchantSzStatusId}
            />
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={activeStep === 2}
            timeout={{ enter: 50, exit: 50, enterDelay: 1000 }}
            unmountOnExit
          >
            <AcquiringSettingsStep2
              domain={domain && parseUri(domain).host}
              confirmToken={confirmToken}
              returnBack={() => setActiveStep(1)}
              submitStep2={confirmDomainTokenHandler}
              domainConfirmationStatus={domainConfirmationStatus}
              downloadFileForConfirmation={downloadFileForConfirmation}
              downloadFileStatus={downloadFileStatus}
            />
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={activeStep === 3}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <AcquiringSettingsStep3
              storeTitle={
                domain &&
                domain.split('/').slice(2).join('/').replace('.com', '').replace('.ru', '')
              }
              domain={domain && parseUri(domain).host}
              id={storeId}
            />
          </CSSTransition>
        </div>
      </div>
    </>
  );
};

AcquiringSettings.propTypes = {
  userTaxStatus: PropTypes.string,
  szStatusFromDb: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

AcquiringSettings.defaultProps = {
  userTaxStatus: null,
  szStatusFromDb: '',
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
  szStatusFromDb: state.auth.szStatusFromDb,
});

export default connect(mapStateToProps)(AcquiringSettings);
