import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import { createPortal } from 'react-dom';

import AuthStatusEnterPassportData from 'modules/profileLegasy/components/AuthStatusEnterPassportData/AuthStatusEnterPassportData';
import AuthStatusWaiting from 'modules/profileLegasy/components/AuthStatusWaiting/AuthStatusWaiting';
import AuthStatusInProgress from 'modules/profileLegasy/components/AuthStatusInProgress/AuthStatusInProgress';
import AuthStatusSuccess from 'modules/profileLegasy/components/AuthStatusSuccess/AuthStatusSuccess';
import AuthStatusFail from 'modules/profileLegasy/components/AuthStatusFail/AuthStatusFail';
import AuthStatusGosUslugiFail from 'modules/profileLegasy/components/AuthStatusGosUslugiFail/AuthStatusGosUslugiFail';
import AuthStatusAlreadyRegistered from 'modules/profileLegasy/components/AuthStatusAlreadyRegistered/AuthStatusAlreadyRegistered';
import AuthStatusAnotherInn from 'modules/profileLegasy/components/AuthStatusAnotherInn/AuthStatusAnotherInn';
import Footer from 'modules/authLegasy/components/Footer/Footer';

import {
  checkIfPartialAuthStatusIsInProcess,
  sentPassportData,
  setAuthStep,
} from 'modules/profileLegasy/actions';
import { getUser, setUserChangedAuthStatus } from 'modules/authLegasy/actions';

import { REGISTERED, AUTHORIZED, EDO } from 'constants/index.js';
import queryString from 'query-string';
// Styles
import './AuthStatus.scss';

const AuthStatusContainer = ({ dispatch, authStep, user, changedAuthStatus }) => {
  const [redirect, setRedirect] = useState(false);

  const location = useLocation();

  /**
   * При заходе на страницу проверяем, если ли у юзера уже начатые процессы
   * получения авторизованной учетки, чтобы он не смогу с двух разных компов
   * отправить запрос на получение авторизованной учетки
   * */
  useEffect(() => {
    checkIfUserAlreadyStartedGettingAuthStatus();

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Отправляем данные для получения авторизованной учетки */
  const sendPassportData = ({
    birthDay, //день рождения
    birthPlace, //место рождения
    firstName, //имя
    lastName, //фамилия
    middleName, //очество
    inn, //инн
    issueDate, //дата выпуска
    passportIssuedBy, //паспорт выданный адрес
    passportIssuer, // паспорт выдан подразделение
    number, //номер паспорта
    serie, //серия паспорта
    clientGroup,
  }) => {
    dispatch(setAuthStep(2));

    dispatch(
      sentPassportData({
        birthDay, //день рождения
        birthPlace, //место рождения
        firstName, //имя
        lastName, //фамилия
        middleName, //очество
        inn, //инн
        issueDate, //дата выпуска
        passportIssuedBy, //паспорт выданный адрес
        passportIssuer, // паспорт выдан подразделение
        number, //номер паспорта
        serie, //серия паспорта
        clientGroup,
      }),
    )
      .then((data) => {
        if (data && data.data === true) {
          dispatch(getUser())
            .then((userData) => {
              /** Send user on success screen */
              if (
                userData &&
                userData.data &&
                userData.data.group &&
                (userData.data.group === AUTHORIZED || userData.data.group === EDO)
              ) {
                dispatch(setAuthStep(3));
              }

              /** Send user on fail screen */
              if (
                userData &&
                userData.data &&
                userData.data.group &&
                userData.data.group === REGISTERED
              ) {
                dispatch(setAuthStep(6));
              }
            })
            .catch(() => {});
        }
      })
      .catch((result) => {
        if (result && result.data && result.data === 'contact_with_support') {
          dispatch(setAuthStep(6));
        } else if (
          result &&
          result.data &&
          result.data === 'doc.you.specified.is.already.in.use.by.another.user'
        ) {
          dispatch(setAuthStep(5));
        } else if (result && result.data && result.data === 'you_have_another_inn') {
          dispatch(setAuthStep(7));
        } else {
          dispatch(setAuthStep(6));
        }
      });
  };

  /**
   * Берем данные по тому, на что сменился changedAuthStatus c вебсокетов,
   * подключенных в AppContent,
   * если changedAuthStatus = REGISTERED - показываем экран фейла
   * если changedAuthStatus =  AUTHORIZED - показываес экран успеха
   * в остальных случаях (которых не должно быть по идее) - показываем фейл
   * */
  useEffect(() => {
    if (changedAuthStatus) {
      if (changedAuthStatus === REGISTERED) {
        dispatch(setAuthStep(6));
        dispatch(setUserChangedAuthStatus(''));
      } else if (changedAuthStatus === EDO) {
        dispatch(setAuthStep(3));
        dispatch(setUserChangedAuthStatus('EDO'));
      } else if (changedAuthStatus === AUTHORIZED) {
        dispatch(setAuthStep(3));
        dispatch(setUserChangedAuthStatus('AUTHORIZED'));
      } else {
        dispatch(setAuthStep(6));
        dispatch(setUserChangedAuthStatus(''));
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [changedAuthStatus]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const checkIfUserAlreadyStartedGettingAuthStatus = () => {
    dispatch(checkIfPartialAuthStatusIsInProcess())
      .then((data) => {
        if (data && data.data === true) {
          dispatch(setAuthStep(2));
        }
      })
      .catch(() => {});
  };

  /** Send user in profile and set initial
   * authStep when user exit from fail screen */
  const resetAuthStep = () => {
    dispatch(setAuthStep(1));
    dispatch(setUserChangedAuthStatus(''));
    setRedirect(true);
  };

  /**
   * Првоеряем есть ли уже у юзера авторизованная
   * учетная запись, если есть, то не даем юзеру зайти на эту страницу
   * */
  useEffect(() => {
    if (authStep === 1) {
      const updateType = queryString.parse(location.search).update === 'true' || false;
      const authorizationType = queryString.parse(location.search).type || null;

      if (
        (user && user.group && user.group === AUTHORIZED && !updateType) ||
        (user.group !== AUTHORIZED && updateType)
      ) {
        setRedirect(true);
      }

      if (user && user.group && user.group === EDO && authorizationType === EDO) {
        setRedirect(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (redirect) {
    return <Redirect to="/lk/profile-settings/account-type" />;
  }

  return createPortal(
    <div className="auth-status-container" data-cy="auth-status-container">
      {authStep === 1 && (
        <AuthStatusEnterPassportData
          className="auth-status_enter-passport-data"
          sendPassportData={sendPassportData}
        />
      )}

      {authStep === 2 && <AuthStatusWaiting className="auth-status_waiting" />}

      {authStep === 3 && (
        <AuthStatusSuccess className="auth-status_success" resetAuthStep={resetAuthStep} />
      )}

      {authStep === 4 && (
        <AuthStatusGosUslugiFail
          className="auth-status_gosuslugi-fail"
          onEnterPassportData={() => dispatch(setAuthStep(1))}
          resetAuthStep={resetAuthStep}
        />
      )}
      {authStep === 5 && (
        <AuthStatusAlreadyRegistered
          className="auth-status_already-registered"
          onEnterPassportData={() => dispatch(setAuthStep(1))}
          resetAuthStep={resetAuthStep}
        />
      )}
      {authStep === 6 && (
        <AuthStatusFail className="auth-status_fail" resetAuthStep={resetAuthStep} />
      )}

      {authStep === 7 && (
        <AuthStatusAnotherInn className="auth-status_fail" resetAuthStep={resetAuthStep} />
      )}

      {authStep === 8 && (
        <AuthStatusInProgress className="auth-status_waiting" resetAuthStep={resetAuthStep} />
      )}

      {/* ФУТЕР */}
      <Footer />
      {/* /ФУТЕР */}
    </div>,
    document.body,
  );
};

AuthStatusContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  authStep: PropTypes.number,
  user: PropTypes.shape({
    group: PropTypes.string.isRequired,
  }).isRequired,
  changedAuthStatus: PropTypes.string,
};
AuthStatusContainer.defaultProps = {
  authStep: 1,
  changedAuthStatus: '',
};

const mapStateToProps = (state) => ({
  authStep: state.profile.authStep,
  user: state.auth.user,
  changedAuthStatus: state.auth.changedAuthStatus,
});

export default connect(mapStateToProps)(AuthStatusContainer);
