import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, connect } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';

import { getPartnershipInfo } from '../../selectors';
import { changeLookOfPhone } from 'helpers';
import { showPopupEditBankCard } from '../../actions';

import styles from './PartnerAboutTab.module.scss';
import Switcher from '../../../shared/components/Switcher/Switcher';
import { getAutoPayTax, changeAutoPayTax } from '../../../../api/partners';
import * as propTypes from 'prop-types';

const PartnerAboutTab = ({ dispatch, userTaxStatus }) => {
  const { partnershipId } = useParams();
  const partnership = useSelector(getPartnershipInfo(partnershipId));
  const [autoPayTax, setAutoPayTax] = React.useState(null);
  const [errorText, setErrorText] = React.useState(null);
  const [autoPayTaxDisabled, setAutoPayTaxDisabled] = React.useState(false);
  if (!partnership) {
    return <Redirect to="/lk/profile-settings/partners" />;
  }

  if (userTaxStatus === 'YES') {
    getAutoPayTax(partnership.partner.platform, partnership.partner.id).then((data) => {
      switch (data.state) {
        case 'TRUE':
          setAutoPayTax(true);
          break;
        case 'FALSE':
          setAutoPayTax(false);
          break;
        case 'DISABLE':
          setAutoPayTax(false);
          setAutoPayTaxDisabled(true);
          break;
        default:
          setAutoPayTax(false);
          setAutoPayTaxDisabled(true);
      }
    });
  }

  function toggleAutoPayTax() {
    if (autoPayTaxDisabled) return;
    setErrorText(null);
    changeAutoPayTax(partnership.partner.platform, partnership.partner.id, !autoPayTax)
      .then(() => {
        setAutoPayTax(!autoPayTax);
      })
      .catch((e) => {
        setErrorText(e.result.message ? e.result.message : e.message || e);
      });
  }

  const infoFields = [];

  if (partnership.partner.fullName) {
    infoFields.push({
      title: 'Полное наименование',
      value: partnership.partner.fullName,
    });
  }

  if (partnership.startDate) {
    infoFields.push({
      title: 'Сотрудничество',
      value: `с ${partnership.startDate}`,
    });
  }

  if (partnership.partner.address) {
    infoFields.push({
      title: 'Адрес',
      value: partnership.partner.address,
    });
  }

  if (partnership.partner.inn) {
    infoFields.push({
      title: 'ИНН',
      value: partnership.partner.inn,
    });
  }

  if (partnership.partner.email) {
    infoFields.push({
      title: 'e-mail',
      value: partnership.partner.email,
    });
  }

  if (partnership.partner.phone) {
    infoFields.push({
      title: 'Телефон',
      value: changeLookOfPhone(partnership.partner.phone),
    });
  }

  if (partnership.maskedPan) {
    infoFields.push({
      title: 'Карта для выплат',
      value: partnership.maskedPan,
      onClick: changeCardNumber,
    });
  }

  if (!partnership?.maskedPan) {
    infoFields.push({
      title: 'Карта для выплат',
      value: 'Добавить карту',
      onValueClick: changeCardNumber,
    });
  }

  if (userTaxStatus === 'YES') {
    infoFields.push({
      title: 'Автоматическая уплата налога',
      value: '',
      toggle: toggleAutoPayTax,
    });
  }

  function changeCardNumber() {
    dispatch(showPopupEditBankCard(partnership.id));
  }

  return (
    <div className={styles.partnerAboutTab}>
      <div className={styles.partnerAboutTabInfo}>
        {infoFields.map((field, index) => (
          <div key={index} className={styles.partnerAboutTabInfoRow}>
            <div className={styles.partnerAboutTabInfoRowTitle}>{field.title}</div>
            <div className={styles.partnerAboutTabInfoRowValue}>
              {!field.onValueClick && <span>{field.value}</span>}
              {field.onValueClick && (
                <button onClick={field.onValueClick}>
                  <span>{field.value}</span>
                </button>
              )}
              {field.onClick && (
                <button onClick={field.onClick}>
                  <IconEdit />
                </button>
              )}
              {field.toggle && (
                <>
                  <Switcher
                    isChecked={autoPayTax === true}
                    ariaLabel=""
                    setIsChecked={field.toggle}
                    className=""
                    subTitle={autoPayTaxDisabled ? 'Свяжитесь с компанией для активации' : ''}
                    title={
                      autoPayTaxDisabled
                        ? 'У компании выключена услуга'
                        : autoPayTax === true
                        ? 'Услуга включена'
                        : autoPayTax === false
                        ? 'Услуга выключена'
                        : ''
                    }
                  />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.errorText}>{errorText}</div>
    </div>
  );
};

PartnerAboutTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userTaxStatus: propTypes.string,
};

const mapStateToProps = (state) => ({
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
});

export default connect(mapStateToProps)(PartnerAboutTab);
