import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import StatusBadge from 'modules/acquiring/components/StatusBadge/StatusBadge';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { ReactComponent as IconHome } from 'static/assets/icon-home.svg';

import './ShopsDropdownItem.scss';

const ShopsDropdownItem = ({ background, title, id, onClick, isOnTop, status, isDropOpen }) => {
  return (
    <button
      className={cx('shops-dropdown-item', {
        'shops-dropdown-item_top': isOnTop,
        'shops-dropdown-item_drop-open': isDropOpen,
      })}
      type="button"
      onClick={onClick}
      data-testid="shops-dropdown-item"
    >
      <div className="shops-dropdown-item__left">
        <div className="shops-dropdown-item__icon-wrap" style={{ background }}>
          <IconHome className="shops-dropdown-item__icon" />
        </div>

        <div className="shops-dropdown-item__text-wrap">
          <p className="shops-dropdown-item__title">{title}</p>
          <p className="shops-dropdown-item__id">ID {id}</p>
        </div>
      </div>

      {isOnTop ? (
        <IconArrow className="shops-dropdown-item__arrow" />
      ) : (
        <StatusBadge status={status} className="shops-dropdown-item__status" />
      )}
    </button>
  );
};

ShopsDropdownItem.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOnTop: PropTypes.bool,
  status: PropTypes.string.isRequired,
  isDropOpen: PropTypes.bool.isRequired,
};

ShopsDropdownItem.defaultProps = {
  isOnTop: false,
};

export default ShopsDropdownItem;
