//import rublesToPenny from '../helpers/rublesToPenny';
import pennyToRubles from '../helpers/pennyToRubles';
import { calculateCommission } from '../../../tools/utils/commission-calculation';

//
// helpers
//

function transferMethodToServiceName(method) {
  let name;

  switch (method) {
    case 'bankcard':
      name = 'bankCard';
      break;
    case 'umoney':
      name = 'uMoney';
      break;
    case 'sz':
      name = 'sz';
      break;
    case 'sbp':
      name = 'sbp';
      break;
    case 'webmoney':
      name = 'webMoney';
      break;
    case 'qiwi':
      name = 'qiwi';
      break;
    default:
      name = '';
  }

  return name;
}

//
// selectors
//

// получить минимальное число, которое можно перевести (в копейках)
export const getMinSumByTransferMethod = (transferMethod) => (state) => {
  return (
    state.moneyTransfer.info.services[transferMethodToServiceName(transferMethod)].minSum || 100
  );
};

// получить максимальное число, которое можно перевести (в копейках)
export const getMaxSumByTransferMethod = (transferMethod) => (state) => {
  return (
    state.moneyTransfer.info.services[transferMethodToServiceName(transferMethod)].maxSum ||
    Infinity
  );
};

// получить баланс (в копейках)
export const getUserBalance = () => (state) => {
  return state.auth.user.clientAccounts[0].balance;
};

// получить коммисию текстом
export const getCommissionText = (transferMethod) => (state) => {
  const serviceName = transferMethodToServiceName(transferMethod);
  const service = state.moneyTransfer.info.services[serviceName];

  if (!service) {
    return 'Комиссия - 0%';
  }

  const { commission } = service;

  if (!commission) {
    return 'Комиссия - 0%';
  }

  let text = 'Комиссия - ';
  text += `${Number(commission.value) || 0}%`;

  if (commission.fixValue) {
    text += ' + ' + pennyToRubles(commission.fixValue) + ' ₽';
  }

  text += `</br>`;

  if (commission.minComm) {
    text += 'Минимальная комиссия - ' + pennyToRubles(commission.minComm) + ' ₽ </br>';
  }

  if (commission.maxComm) {
    text += 'Максимальная комиссия- ' + pennyToRubles(commission.maxComm) + ' ₽ </br>';
  }

  return text;
};

// получить комиссию [общаяСумма - сумма] (в копейках)
export const getCommissionByMoneyTransferMethod = (transferMethod) => (state) => {
  const sum = state.moneyTransfer.payment.sum;
  const totalSum = getTotalSumByMoneyTransferMethod(transferMethod)(state);

  return totalSum - sum;
};

// получить общую сумму [сумма + комиссия] (в копейках)
export const getTotalSumByMoneyTransferMethod = (transferMethod) => (state) => {
  const serviceName = transferMethodToServiceName(transferMethod);
  const service = state.moneyTransfer.info.services[serviceName];
  const sum = Number(state.moneyTransfer.payment.sum);

  if (!service) {
    return Math.round(sum);
  }

  const { commission } = service;

  return calculateCommission({ sum: sum, commission: commission, inverted: false });
};

export const getTotalBalanceByMoneyTransferMethod = (transferMethod) => (state) => {
  const serviceName = transferMethodToServiceName(transferMethod);
  const service = state.moneyTransfer.info.services[serviceName];
  const balance = Math.min(
    state.auth.user.clientAccounts[0].balance,
    state.moneyTransfer.info.maxLimitSum,
  );

  if (!service) {
    return Math.round(balance);
  }
  const { commission } = service;
  return calculateCommission({ sum: balance, commission: commission, inverted: true });
};

// получить номер телефон
export const getUserPhone = () => (state) => {
  return state.auth.user.login;
};

// введен ли номер карты или кошелька
export const isCardNumberInserted = () => (state) => {
  return state.moneyTransfer.payment.cardOrWalletNumber === 16;
};
