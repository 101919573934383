import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import Scrollbar from 'react-scrollbars-custom';

import { getMerchantsHistory } from 'modules/acquiring/actions';
import AcquiringOperationItem from 'modules/acquiring/components/AcquiringOperationItem/AcquiringOperationItem';
import FiltersBar from 'modules/acquiring/components/FiltersBar/FiltersBar';
import FiltersSideBar from 'modules/acquiring/components/FiltersSideBar/FiltersSideBar';
import { getInvoicePDF } from 'modules/profileLegasy/actions';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import NoOperationsMade from 'modules/shared/components/NoOperationsMade/NoOperationsMade';

import downloadFile from 'helpers/downloadFile';

import './OperationsTab.scss';

export const OperationsTab = ({
  dispatch,
  chosenSumFromFilters,
  chosenSumUntilFilters,
  chosenPaymentWaysFilters,
  chosenPaymentStatusFilters,
  searchWord,
  datesRange,
  isOperationsCalendarFilterOpen,
  chosenShop,
  merchantsOperations,
}) => {
  const windowSize = useWindowSize();
  const [currentOpenItem, setCurrentOpenItem] = useState(null);
  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [isFiltersChosen, setIsFiltersChosen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFailToLoad, setIsFailToLoad] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [endYear, setEndYear] = useState(null);
  const [day, setDay] = useState(new Date().getDate());
  const [endDay, setEndDay] = useState(new Date().getDate());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [endMonth, setEndMonth] = useState(null);
  const [states, setStates] = useState(null);
  const [cashSources, setCashSources] = useState(null);
  const [filteredList, setFilteredList] = useState(null);
  const zoneOffset = new Date().getTimezoneOffset();

  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 20;

  /** Делаем запрос на денежные запросы юзера */
  const getRequestsForPage = useCallback(
    (page) => {
      setIsLoading(true);
      dispatch(
        getMerchantsHistory({
          cashSources: cashSources || null,
          day: day || null,
          endDay: endDay || null,
          endMonth: endMonth || null,
          endYear: endYear || null,
          chosenSumUntilFilters: chosenSumUntilFilters || null,
          month,
          page,
          pageSize,
          year,
          zoneOffset,
          merchantUuid: chosenShop,
          chosenSumFromFilters: chosenSumFromFilters || null,
          states: states || null,
          maxSum: chosenSumUntilFilters * 100 || null,
          minSum: chosenSumFromFilters * 100 || null,
        }),
      )
        .then((res) => {
          if (res && res.data && res.data.numberOfPages !== null) {
            setHasMore(!(res.data.numberOfPages <= page));
          }
        })
        .catch((result) => {
          if (result && result.result && result.result.code === 400) {
            setIsLoading(false);
            setIsFailToLoad(true);
          }
          setHasMore(false);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [
      month,
      year,
      dispatch,
      zoneOffset,
      pageSize,
      chosenShop,
      day,
      endDay,
      endMonth,
      endYear,
      chosenSumUntilFilters,
      chosenSumFromFilters,
      states,
      cashSources,
    ],
  );

  /** Загружаем первую порцию операций для текущего месяца и года */
  useEffect(() => {
    if (month && year && endMonth && endYear) {
      getRequestsForPage(pageNumber);
    }
  }, [pageNumber, month, year, endMonth, endYear, zoneOffset, getRequestsForPage]);

  useEffect(() => {
    if (
      chosenSumFromFilters ||
      chosenSumUntilFilters ||
      (chosenPaymentWaysFilters && chosenPaymentWaysFilters.length) ||
      (chosenPaymentStatusFilters && chosenPaymentStatusFilters.length)
    ) {
      setIsFiltersChosen(true);
    } else {
      setIsFiltersChosen(false);
    }
    setPageNumber(1);
  }, [
    chosenSumFromFilters,
    chosenSumUntilFilters,
    chosenPaymentWaysFilters,
    chosenPaymentStatusFilters,
  ]);

  useEffect(() => {
    if (datesRange && datesRange[0] && datesRange[0].startDate && datesRange[0].endDate) {
      setYear(datesRange[0].startDate.getFullYear());
      setEndYear(datesRange[0].endDate.getFullYear());

      setMonth(datesRange[0].startDate.getMonth() + 1);
      setEndMonth(datesRange[0].endDate.getMonth() + 1);

      setDay(datesRange[0].startDate.getDate());
      setEndDay(datesRange[0].endDate.getDate());
    } else {
      setYear(null);
      setEndYear(null);

      setMonth(null);
      setEndMonth(null);

      setDay(null);
      setEndDay(null);
    }
    setPageNumber(1);
  }, [datesRange]);

  useEffect(() => {
    if (chosenPaymentStatusFilters && chosenPaymentStatusFilters.length) {
      const chosenPaymentStatusFiltersForServer = chosenPaymentStatusFilters.map(
        (item) => item.value,
      );
      setStates(chosenPaymentStatusFiltersForServer);
    } else {
      setStates(null);
    }
  }, [chosenPaymentStatusFilters]);

  useEffect(() => {
    if (chosenPaymentWaysFilters && chosenPaymentWaysFilters.length) {
      const chosenPaymentWaysFiltersForServer = chosenPaymentWaysFilters.map((item) => item.value);
      setCashSources(chosenPaymentWaysFiltersForServer);
    } else {
      setCashSources(null);
    }
  }, [chosenPaymentWaysFilters]);

  useEffect(() => {
    const result =
      merchantsOperations &&
      merchantsOperations.payments &&
      merchantsOperations.payments.length >= 1 &&
      merchantsOperations.payments.filter((item) => {
        return (
          !searchWord ||
          (searchWord &&
            ((item.id && item.id.toString().indexOf(searchWord) > -1) ||
              (item.name && item.name.indexOf(searchWord) > -1) ||
              (item.dateTimeAdd && item.dateTimeAdd.indexOf(searchWord) > -1) ||
              (item.sum && item.sum.toString().indexOf(searchWord) > -1) ||
              (item.cardPan && item.cardPan.indexOf(searchWord) > -1) ||
              item.merchantOrderId.indexOf(searchWord) > -1))
        );
      });

    setFilteredList(result);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    searchWord,
    merchantsOperations,
    chosenShop,
    chosenSumUntilFilters,
    chosenPaymentStatusFilters,
    datesRange,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Клик по операции */
  const openItem = (id, e) => {
    if (e.target.className === 'acquiring-operation-item__download-check-text') return;
    if (currentOpenItem !== id) {
      setCurrentOpenItem(id);
    } else {
      setCurrentOpenItem(null);
    }
  };

  /** Запрос на генерацию PDF для скачивания чека */
  const handleDownloadInvoice = (e, id) => {
    e.preventDefault();

    dispatch(getInvoicePDF(id))
      .then((response) => {
        if (response) {
          const blob = new Blob([response], { type: 'application/pdf' });

          downloadFile(blob, `samozanyatye-${id}.pdf`);
        }
      })
      .catch(() => {});
  };

  /** Скачиваем картинку чека */
  const downloadImageCheck = (downloadUrl, fileName) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';
    xhr.open('GET', downloadUrl, true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE) {
        const file = new Blob([xhr.response], { type: 'image/jpeg' });
        downloadFile(file, `${fileName}.jpg`);
      }
    };

    xhr.send();
  };

  return (
    <div
      className={cx('operations-tab', {
        'operations-tab_calendar-open':
          windowSize.innerWidth <= 900 && isOperationsCalendarFilterOpen,
      })}
    >
      {windowSize.innerWidth > 1030 && <h1 className="operations-tab__title">Операции</h1>}

      <FiltersBar
        className="operations-tab__filters"
        openFiltersSidebar={() => setIsFilterSidebarOpen(true)}
        isFiltersChosen={isFiltersChosen}
      />

      <FiltersSideBar
        mounted={isFilterSidebarOpen}
        closeHandler={() => setIsFilterSidebarOpen(false)}
      />

      {/* КОНТЕНТ ТАБЛИЦЫ */}
      <div className="operations-tab__content-wrap">
        <Scrollbar
          className="operations-tab__content-scrollbar"
          trackYProps={{
            /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
            renderer: ({ elementRef, style, ...restProps }) => {
              return <span {...restProps} ref={elementRef} className="operations-tab__track" />;
            },
          }}
          thumbYProps={{
            renderer: ({ elementRef, style, ...restProps }) => {
              return <span {...restProps} ref={elementRef} className="operations-tab__thumb" />;
            },
            /* eslint-enable react/prop-types, react/jsx-props-no-spreading */
          }}
        >
          <InfiniteScroll
            pageStart={1} // It is second actual
            initialLoad={false}
            loadMore={(p) => {
              setIsLoading(true);
              getRequestsForPage(p);
            }}
            hasMore={hasMore}
            useWindow={false}
            key={`${month}_${year}`}
            getScrollParent={() => document.querySelector('.ScrollbarsCustom-Scroller')}
          >
            <div className="operations-tab__list">
              {filteredList &&
                filteredList.length >= 1 &&
                filteredList.map((item) => (
                  <AcquiringOperationItem
                    item={item}
                    key={item.id}
                    currentOpenItem={currentOpenItem}
                    onClick={(e) => openItem(item.id, e)}
                    downloadCheck={
                      item.taxPdfCheckUrl
                        ? () => downloadImageCheck(item.taxPdfCheckUrl, item.merchantOrderId)
                        : (e) => handleDownloadInvoice(e, item.id)
                    }
                  />
                ))}
            </div>
          </InfiniteScroll>
          {isLoading && (
            <div className="operations-tab__loading">
              <CircleLoader className="operations-tab__loading-loader" />
            </div>
          )}

          {/* СООБЩЕНИЕ КОГДА НЕТ ОПЕРАЦИЙ */}
          {filteredList && !filteredList.length && !isLoading && (
            <NoOperationsMade
              // text="В магазине пока нет проведенных платежей. Как только будет совершена покупка, она отобразится тут."
              text="На указанный период в магазине нет проведенных платежей. Попробуйте выбрать другие даты"
              className="operations-tab__no-operations-message"
            />
          )}
          {/* /СООБЩЕНИЕ КОГДА НЕТ ОПЕРАЦИЙ */}

          {/* СООБЩЕНИЕ КОГДА НЕ УДАЛОСЬ ЗАГРУЗИТЬ ОПЕРАЦИИ */}
          {isFailToLoad && !isLoading && (
            <NoOperationsMade className="deals__no-operations-message">
              <div className="no-operations-made__text">
                Произошла какая-то ошибка. Не удалось загрузить данные. Попробуйте позже, а если
                ошибка не пройдет
                <button className="no-operations-made__text-link js-help" type="button">
                  напишите нам
                </button>
                .
              </div>
            </NoOperationsMade>
          )}
          {/* /СООБЩЕНИЕ КОГДА НЕ УДАЛОСЬ ЗАГРУЗИТЬ ОПЕРАЦИИ */}
        </Scrollbar>
      </div>
      {/* /КОНТЕНТ ТАБЛИЦЫ */}
    </div>
  );
};

OperationsTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  chosenSumFromFilters: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chosenSumUntilFilters: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chosenPaymentWaysFilters: PropTypes.arrayOf(PropTypes.shape({})),
  chosenPaymentStatusFilters: PropTypes.arrayOf(PropTypes.shape({})),
  datesRange: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
    }),
  ),
  searchWord: PropTypes.string,
  isOperationsCalendarFilterOpen: PropTypes.bool,
  chosenShop: PropTypes.string.isRequired,
  merchantsOperations: PropTypes.shape({
    payments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        logo: PropTypes.string,
        name: PropTypes.string,
        dateTimeAdd: PropTypes.string,
        sum: PropTypes.number,
        writeOff: PropTypes.bool,
        currency: PropTypes.string,
        state: PropTypes.string,
        id1: PropTypes.string,
        cardPan: PropTypes.string,
        merchantOrderId: PropTypes.string,
        dateTimeFinished: PropTypes.string,
        dateTimeProcessed: PropTypes.string,
        dateSuccess: PropTypes.string,
        dateCancelled: PropTypes.string,
        taxPdfCheckUrl: PropTypes.string,
      }),
    ),
  }),
};

OperationsTab.defaultProps = {
  chosenSumFromFilters: '',
  chosenSumUntilFilters: '',
  chosenPaymentWaysFilters: [],
  chosenPaymentStatusFilters: [],
  searchWord: null,
  datesRange: null,
  isOperationsCalendarFilterOpen: false,
  merchantsOperations: {},
};

const mapStateToProps = (state) => ({
  chosenSumFromFilters: state.acquiring.chosenSumFromFilters,
  chosenSumUntilFilters: state.acquiring.chosenSumUntilFilters,
  chosenPaymentWaysFilters: state.acquiring.chosenPaymentWaysFilters,
  chosenPaymentStatusFilters: state.acquiring.chosenPaymentStatusFilters,
  searchWord: state.acquiring.searchWord,
  datesRange: state.acquiring.datesRange,
  isOperationsCalendarFilterOpen: state.acquiring.isOperationsCalendarFilterOpen,
  merchantsOperations: state.acquiring.merchantsOperations,
});

export default connect(mapStateToProps)(OperationsTab);
